@charset "UTF-8";
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}


svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.container,
.container-md {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
   .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
   .container-md, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
   .container-md, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
   .container-md, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.form-label {
  margin-bottom: 0.5rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
 :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
 :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.collapse:not(.show) {
  display: none;
}


.dropdown {
  position: relative;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-md {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}

/* rtl:begin:remove */

/* rtl:end:remove */

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.visible {
  visibility: visible !important;
}

/*# sourceMappingURL=bootstrap.css.map */
.navbar-dark {
  background: #016698 !important;
}
.nav-link {
  color: white !important;
}
.nav-link:hover {
  color: #ffbf00 !important;
}

.navbar-brand {
  color: white;
  padding-left: 4em;
  padding-right: 4em;
  font-size: 22px !important;
}
.col-md-6 {
  padding-top: 2em;
  padding-left: 2.5em !important;
}
.maintext {
  padding-left: 4em;
  padding-right: 0.5em;
  font-size: 17px;
}
.listtittle {
  padding-left: 4.5em;
  padding-right: 0.5em;
  font-size: 17px;
}
.listitems {
  padding-left: 4.2em;
  font-size: 17px;
}

.index-padding {
  padding-top: 1.5em;
  padding-bottom: 2em;
}

/* from index  */

.index-img1,
.index-img2 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1.75;
}

/* @media (320px < width < 480px) {
  .index-img {
    height: 500px;
  }
} */

/* Footer */

.footer-privacy {
  color: white;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}

.main-footer {
  background-color: black;
  margin-top: 2em;
  padding-bottom: 6em;
}

.copyright {
  color: gray;
  margin: auto;
  text-align: center;
}

.dev-by {
  color: gray;
  margin: auto;
}
.edgar {
  color: greenyellow;
  text-decoration: none;
  margin: auto;
}
.edgar:hover {
  color: chartreuse;
}

@keyframes bannermove {
  from {
    right: -1200px;
  }
  to {
    right: 1400px;
  }
}

/* Product nav bar */

.pumpHidden {
  display: none;
}

.link1 {
  display: block;
  background-color: #016698;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #555;
  margin-bottom: 5px;
}

.link2Tittle {
  display: block;
  background-color: #169ee3;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  border: 0.5px solid #555;
}

.link2 {
  display: block;
  background-color: whitesmoke;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  border: 0.5px solid #555;
}

.link2.active2 {
  background-color: lightsteelblue;
  color: black;
}

.link2:hover:not(.active2) {
  background-color: #778899;
  color: white;
}

.nav2 {
  list-style-type: none;
  margin: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 220px;
  background-color: white;
}
.nav2title {
  text-align: center;
  display: block;
  background-color: #169ee3;
  color: rgb(48, 12, 12);
  padding: 10px;
  padding-left: 10px;
  text-decoration: none;
  border: 1px solid #555;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}
.col-md-9 {
  padding-left: 3em;
  padding-right: 1.5em;
  padding-top: 3em !important;
}
.col-md-3 {
  padding-left: 2.5em;
  padding-right: 3em;
  padding-top: 3em;
}
.contactus {
  text-align: center;
  font-size: 30px;
  margin-top: 2em;
  margin-bottom: 0.5em;
}
.col-md-12 {
  padding-right: 3.5em;
  padding-left: 3.5em !important;
}
.linebreak {
  width: 400px;
}
.fluid-power-formulas {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
}
.thank-you-text {
  font-size: 40px;
  text-align: center;
  padding-top: 2.5em;
}
.bi-check-lg {
  margin-left: auto;
  margin-right: auto;
  color: green;
}
.thank-you-text-2 {
  text-align: center;
}
.maintenance-2-span {
  padding-left: 2em;
  position: relative;
  aspect-ratio: 1.75;
}

#maintenance-2 {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left center;
     object-position: left center;
  margin-right: 1em;
  margin-left: 1.5em;
}

.preventive-maintenance-text {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 1em;
  padding-bottom: 10em;
}

.oil-analysis-span {
  padding-left: 2em;
  position: relative;
  aspect-ratio: 1.75;
}

#oil-analysis2 {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left center;
     object-position: left center;
  margin-right: 1em;
  margin-left: 1.5em;
}

.oil-analysis-text {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 1em;
}

/* Product Folder */

.directional-control-valves-div {
  position: relative;
  height: 85px;
}

#directional-control-valves,
#flow-control-valves,
#piston-pump,
#radial-piston-pump,
#vane-pump,
#double-vane-pump,
#variable-displacement-vane-pump,
#gear-pump,
#dump-pump,
#geroler-motors,
#vane-motors,
#linear-guides,
#ball-screws,
#maintenance1,
#oilanalysis2 {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

/* Flow Control Valves */

.flow-control-valves-div {
  position: relative;
  height: 128px;
}

/* Piston Pumps */

.piston-pump-div {
  position: relative;
  height: 287px;
}

.radial-piston-pump-div {
  position: relative;
  height: 130px;
}

/* Vane Pumps */

.vane-pump-div {
  position: relative;
  height: 117px;
}

.double-vane-pump-div {
  position: relative;
  height: 129px;
}

.variable-displacement-vane-pump-div {
  position: relative;
  height: 120px;
}

/* Gear Pumps */

.gear-pump-div {
  position: relative;
  height: 105px;
}
.gear-pump-div {
  position: relative;
  height: 105px;
}

.dump-pump-div {
  position: relative;
  height: 108px;
}

/* Motors */
.geroler-motors-div {
  position: relative;
  height: 137px;
}
.vane-motors-div {
  position: relative;
  height: 132px;
}

.piston-pump,
.fixed-piston-pump,
.product-title-text {
  text-align: center;
  margin-top: 3em;
  font-size: 20px !important;
}


.product-pic {
  display: block;
  margin-left: auto;
  margin-right: auto !important;
}

.btn-info {
  display: block;
  margin-left: auto;
  width: 150px;
  margin-right: auto !important;
}

.a10-table-title,
.gear-pump-table-title,
.product-title-table-text {
  text-align: center;
  background-color: #016698 !important;
  color: white !important;
}
.inside-table-text {
  vertical-align: middle;
  text-align: center !important;
}

.pressure-piston-pump-rpm {
  text-align: center;
  background-color: lightblue !important;
}
.centering-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MANIFOLD `<a>` ELEMENTS */

/* Products - Linear Guides */
.linear-guides-div {
  position: relative;
  aspect-ratio: 1.5924855491;
  margin-left: auto;
  margin-right: auto;
}
/* 
Products - ball screws  */
.ball-screws-div {
  position: relative;
  aspect-ratio: 1.728135693;
  margin-left: auto;
  margin-right: auto;
}

/* PUMP FORM */

.main-div-pump-form {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 1em;
}

.pump-labels {
  width: 160px;
  display: inline-block;
  margin-bottom: 4px;
}
.input-fields {
  display: inline-block;
  margin-bottom: 1rem;
}

.hydraulic-pump-configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

.pump-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

/* PUMP FORM END */

/* FLOWMETER-FORM */

.flowmeter-form-labels {
  width: 150px;
  display: inline-block;
  margin-bottom: 4px;
}

.flowmeter-configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.flowmeter-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

/* FLOWMETER-FORM END */

/* MOTOR-FORM */

.motor-form-labels {
  width: 150px;
  display: inline-block;
  margin-bottom: 4px;
}

.hydraulicMotor1Configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.motor-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

.form-label-style {
  display: inline-block;
}

.form-display-none {
  display: none;
}

.form-display-block {
  display: block;
}

.form-textarea {
  width: 300px;
  height: 200px;
  display: block;
}

@media (max-width: 525px) {
  .form-textarea {
    width: 200px;
    height: 150px;
    display: block;
  }
}

/* MOTOR-FORM END */

/* TABLE-SORT-PART-NUMBER */

/* TABLE-SORT-PART-NUMBER END */

/* Interactive start */
.st0 {
  opacity: 0.8;
}
.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 10.2172, 10.2172;
}
.st3 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.st4 {
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.st5 {
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st6 {
  font-family: "MyriadPro-Regular";
}
.st7 {
  font-size: 91.3033px;
}
.st8 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #cccccc;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #cccccc;
}
.st11 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 15.3029, 15.3029;
}
.st12 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.2633, 12.2633;
}
.st13 {
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 3;
}
.st14 {
  opacity: 0.5;
  fill: none;
  stroke: #ed1c24;
  stroke-width: 15;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st15 {
  opacity: 0.5;
  fill: none;
  stroke: #0000ff;
  stroke-width: 15;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #ffce3c;
}
.st17 {
  font-size: 34.5528px;
}
.st18 {
  font-size: 29.9357px;
}
.st19 {
  fill: #ff0000;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st20 {
  fill: #f2f2f2;
}
.st21 {
  font-size: 50px;
}
.st22 {
  fill: none;
  stroke: #000000;
  stroke-width: 15;
  stroke-miterlimit: 10;
}
.st23 {
  fill: #00ff00;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st24 {
  font-size: 30px;
}
/* Interactive end */

/* Contact Us beginning */

.svg-drawing-container {
  padding-top: 1em;
}

.contact-iframe {
  border: 0;
  padding-right: 2em;
  padding-bottom: 1em;
}

.contact-your-message-input {
  display: none;
}

/* Contact Us end */

/* Inventory beginning */
.invy-text {
  margin-top: 50px;
  margin-bottom: 20px;
  padding-right: 4em;
  padding-left: 4em;
}
/* Inventory End */

/* Formula beginning */
/* Force (lbs) = */
#pressure1 {
  width: 120px;
}
#area1 {
  width: 99px;
  margin: 0px 5px;
}

/* Gpm =  */
#rpm2 {
  width: 99px;
}
#displacement2 {
  width: 150px;
  margin-left: 5px;
}
.equals {
  margin-left: 5px;
  margin-right: 5px;
}

/* Hyd HP =  */
#gpm3 {
  width: 99px;
}
#psi3 {
  width: 120px;
  margin-left: 5px;
}

/* Torque (in lbs) =  */
#psi4 {
  width: 110px;
}
#area4 {
  width: 99px;
  margin-left: 5px;
}

/* Torque (in lbs) =  */
#hp5 {
  width: 99px;
}
#rpm5 {
  width: 99px;
  margin: 0px 5px;
}

/* Cylinder area (in²) = */
#dia6 {
  width: 110px;
}

/* EREA - Effected Rod End Area (in²) =  */
#dia12 {
  width: 110px;
}
#rod12 {
  width: 110px;
  margin-left: 5px;
}

/* Cyl Time (seconds) = */
#area7 {
  width: 99px;
}
#stroke7 {
  width: 99px;
  margin-left: 5px;
}
#gpm7 {
  width: 99px;
  margin: 0px 5px;
}

/* Cyl HP = */
#cylSpeed8 {
  width: 145px;
}
#cylForce8 {
  width: 125px;
  margin-left: 5px;
}

/* Cyl Adj. gpm on retract = */
#cylArea9 {
  width: 110px;
}
#gpm9 {
  width: 99px;
  margin-left: 5px;
}
#erea9 {
  width: 99px;
  margin: 0px 5px;
}

/* Cyl Speed (ft/min) = */
#stroke10 {
  width: 99px;
}
#time10 {
  width: 125px;
  margin: 0px 5px;
}

/* Cyl Speed (ft/min) = */
#gpm11 {
  width: 99px;
}
#area11 {
  width: 110px;
  margin: 0px 5px;
}
/* Formula end */

/* contact form */
.contact-us-form {
  padding-left: 1em;
}
.get-in-touch {
  padding-left: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

.inventory-list {
  color: black;
  display: block;
  text-decoration: none;
}
.inventory-list:hover {
  color: #016698;
  text-decoration: underline;
  text-decoration-color: #016698;
}
.footer-margin {
  padding-bottom: 10em;
}
.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* social  */
.see-more {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.see-more:hover {
  color: rgb(77, 77, 104);
  text-decoration: underline;
  text-decoration-color: none;
}
.see-less {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  display: none;
}
.see-less:hover {
  color: rgb(77, 77, 104);
  text-decoration: underline;
  text-decoration-color: none;
}

.video-blog-description {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: rgb(247, 242, 242);
  display: none;
}

.social-icons {
  display: flex;
  margin-right: 0.2em;
  margin-top: 0.1em;
  margin-bottom: 0.4em;
}
.fallback {
  display: flex;
  margin: 2em;
  margin-bottom: 3em;
}
.blog-input-spanish {
  margin-left: 4em;
}

/* Blog index */

.blog-div-image {
  position: relative;
  aspect-ratio: 1.9047;
  margin: auto;
  height: 315px;
}

@media (min-width: 320.02px) and (max-width: 809.98px) {
  .blog-div-image {
    height: 230px;
    position: relative;
    aspect-ratio: 1.9047;
    margin: auto;
  }
}

#blog-image {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

/* Blog-post-spanish */

.blog-post-spanish-space {
  margin-top: 0.2em;
}

/* Product Index */
/* Dropdown Button */
.dropbtn1,
.dropbtn2,
.dropbtn3,
.dropbtn4,
.dropbtn5,
.dropbtn6,
.dropbtn7,
.dropbtn8 {
  background-color: #0a567f;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-bottom: 2px;
}

/* Dropdown button on hover & focus */
.dropbtn1:hover,
.dropbtn1:focus,
.dropbtn2:hover,
.dropbtn2:focus,
.dropbtn3:hover,
.dropbtn3:focus,
.dropbtn4:hover,
.dropbtn4:focus,
.dropbtn5:hover,
.dropbtn5:focus,
.dropbtn6:hover,
.dropbtn6:focus,
.dropbtn7:hover,
.dropbtn7:focus,
.dropbtn8:hover {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown1,
.dropdown2,
.dropdown3,
.dropdown4,
.dropdown5,
.dropdown6,
.dropdown7,
.dropdown8 {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content1,
.dropdown-content2,
.dropdown-content3,
.dropdown-content4,
.dropdown-content5,
.dropdown-content6,
.dropdown-content7,
.dropdown-content8 {
  display: none;

  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content1 a,
.dropdown-content2 a,
.dropdown-content3 a,
.dropdown-content4 a,
.dropdown-content5 a,
.dropdown-content6 a,
.dropdown-content7 a,
.dropdown-content8 a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

/* Change color of dropdown links on hover */
.dropdown-content1 a:hover,
.dropdown-content2 a:hover,
.dropdown-content3 a:hover,
.dropdown-content4 a:hover,
.dropdown-content5 a:hover,
.dropdown-content6 a:hover,
.dropdown-content7 a:hover,
.dropdown-content8 a:hover {
  background-color: #ddd;
}
/* . {
  background-color: #ddd;
} */
.dropdown-title {
  color: #2980b9;
  padding: 10px;
  text-decoration: none;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */

.hydraulic-class-items,
.mechanical-class-items {
  display: block;
  text-decoration: none;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}
.do-not-show-hydraulics,
.do-not-show-mechanical {
  display: none;
  z-index: 1;
}

.hydraulic-button-wrap-div,
.mechanical-button-wrap-div {
  margin-bottom: 0.5em;
  border-bottom: double;
}

.hydraulic-drop-button:hover,
.mechanical-drop-button:hover {
  color: rgb(44, 68, 134);
  -webkit-text-decoration: underline dotted rgb(44, 68, 134);
          text-decoration: underline dotted rgb(44, 68, 134);
}

.show1,
.show2,
.show3,
.show4,
.show5,
.show6,
.show7,
.show8,
.showPumps {
  display: block;
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.product-centering {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.product-col {
  margin-bottom: 2em;
}

/* Finish product Index */

/* Filter Elements */

.filter-elements-first-col {
  margin-top: 4em;
}

.filter-element {
  color: black;
  text-decoration: none;
}

.filter-element:hover {
  color: blue;
  text-decoration: underline;
  text-decoration-color: red;
}
.contact-us {
  color: rgb(6, 6, 145);
  font-weight: bold;
  text-decoration: none;
}

.contact-us:hover {
  text-decoration: underline;
  text-decoration-color: blue;
}

/* Carousel */

.tu-world {
  position: relative;
  margin-top: 0.2em;
  aspect-ratio: 5.41;
  margin-left: auto;
  margin-right: auto;
}

/* .fill-carousel {
  background-color: #1e4474;
} */

#carousel-inner {
  animation-name: carousel-inner;
  animation-duration: 3s;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% top;
     object-position: 50% top;
}

@keyframes carousel-inner {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Carousel blog Spanish */

.carousel-spanish-div {
  position: relative;
  margin-top: 0.2em;
  aspect-ratio: 1.7777777778;
  margin-left: auto;
  margin-right: auto;
}

/* .fill-carousel {
  background-color: #1e4474;
} */

#carousel-inner-spanish-blog {
  animation-name: carousel-inner;
  animation-duration: 1s;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% top;
     object-position: 50% top;
}

@keyframes carousel-inner-spanish-blog {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* CHAT UI */
.chat-button {
  background-color: #555;
  color: black;
  padding: 10px 20px;
  border: 3px solid #f1f1f1;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  left: 28px;
  width: 280px;
}
.inside-chat-button {
  background-color: #0a6d49;
  color: white;
  padding: 3px 15px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 40%;
  margin-bottom: 5px;
}
.chat-container {
  display: none;
  background-color: #555;
  position: fixed;
  bottom: 23px;
  left: 28px;
  width: 280px;
  border: 3px solid #f1f1f1;
}

.btn-container {
  max-width: 280px;
  padding: 5px 10px;
  background-color: #555;
}
.btn-send {
  background-color: #04aa6d;
  color: white;
  padding: 5px 20px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}
/* Using btn-close2 because Bootstrap already has a btn-close class */
.btn-close2 {
  background-color: red;
  color: white;
  padding: 5px 20px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}
.input-container {
  position: relative;
  box-shadow: 0px 2px 5px 0px rgba (0, 0, 0, 0.3);
  background-color: white;
  color: black;
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
  transition: 0.4s;
}
.chat-display {
  position: relative;
  overflow-y: auto;
  height: 250px;
  background-color: white;
  color: black;
  padding: 2px;
  border: none;
  cursor: pointer;
  margin-bottom: 1px;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
}
.chat-display-header {
  color: white;
  padding-left: 10px;
}
.ai-col:before {
  content: "AI-Luis.";
}
.message_container {
  position: relative;
  margin-top: 5px;
}
.message_text {
  word-wrap: break-word;
}
/* Google tags */

.main-cookies {
  display: none;
  background-color: whitesmoke;
  color: black;
  margin: auto;
  padding: 10px 20px;
  border: 5px solid black;
  cursor: pointer;
  position: fixed;
  top: 30%;
  left: 5%;
}

/* Quick Contact English  */
.quick-contact-english-text {
  text-align: center;
  background-color: #f4f4f4;
  margin-top: 0.2em;
}

.pagination-container{display:flex;list-style-type:none}.pagination-container .pagination-item{padding:0 12px;height:32px;text-align:center;margin:auto 4px;color:rgba(0,0,0,.87);display:flex;box-sizing:border-box;align-items:center;letter-spacing:.01071em;border-radius:16px;line-height:1.43;font-size:13px;min-width:32px}.pagination-container .pagination-item.dots:hover{background-color:rgba(0,0,0,0);cursor:default}.pagination-container .pagination-item:hover{background-color:rgba(0,0,0,.04);cursor:pointer}.pagination-container .pagination-item.selected{background-color:rgba(0,0,0,.08)}.pagination-container .pagination-item .arrow::before{position:relative;content:"";display:inline-block;width:.4em;height:.4em;border-right:.12em solid rgba(0,0,0,.87);border-top:.12em solid rgba(0,0,0,.87)}.pagination-container .pagination-item .arrow.left{transform:rotate(-135deg) translate(-50%)}.pagination-container .pagination-item .arrow.right{transform:rotate(45deg)}.pagination-container .pagination-item.disabled{pointer-events:none}.pagination-container .pagination-item.disabled .arrow::before{border-right:.12em solid rgba(0,0,0,.43);border-top:.12em solid rgba(0,0,0,.43)}.pagination-container .pagination-item.disabled:hover{background-color:rgba(0,0,0,0);cursor:default}
