.navbar-dark {
  background: #016698 !important;
}
.nav-link {
  color: white !important;
}
.nav-link:hover {
  color: #ffbf00 !important;
}

.navbar-brand {
  color: white;
  padding-left: 4em;
  padding-right: 4em;
  font-size: 22px !important;
}
.col-md-6 {
  padding-top: 2em;
  padding-left: 2.5em !important;
}
.maintext {
  padding-left: 4em;
  padding-right: 0.5em;
  font-size: 17px;
}
.listtittle {
  padding-left: 4.5em;
  padding-right: 0.5em;
  font-size: 17px;
}
.listitems {
  padding-left: 4.2em;
  font-size: 17px;
}

.index-padding {
  padding-top: 1.5em;
  padding-bottom: 2em;
}

/* from index  */

.index-img1,
.index-img2 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1.75;
}

/* @media (320px < width < 480px) {
  .index-img {
    height: 500px;
  }
} */

.image1 {
  position: "relative";
  top: "1px";
  left: "1px";
  object-fit: "scale-down";
  object-position: "center";
  display: flex;
}

/* Footer */

.footer-privacy {
  color: white;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
}
.main-footer2 {
  background-color: black;
  margin-top: 2em;
  margin-bottom: 3em;
}

.main-footer {
  background-color: black;
  margin-top: 2em;
  padding-bottom: 6em;
}

.copyright {
  color: gray;
  margin: auto;
  text-align: center;
}

.dev-by {
  color: gray;
  margin: auto;
}
.edgar {
  color: greenyellow;
  text-decoration: none;
  margin: auto;
}
.edgar:hover {
  color: chartreuse;
}
.footer-icon {
  margin-left: 0.5em;
  padding-top: 0.3em;
}
.social-icon-col {
  padding-top: 0.3em;
  margin-bottom: 0.3em;

  text-align: right;
}

.banner-text {
  position: absolute;
  color: #016698;
  animation: bannermove 20s linear infinite;
  width: 1400px;
}

@keyframes bannermove {
  from {
    right: -1200px;
  }
  to {
    right: 1400px;
  }
}

/* Product nav bar */

.pumpHidden {
  display: none;
}

.link1 {
  display: block;
  background-color: #016698;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #555;
  margin-bottom: 5px;
}

.link2Tittle {
  display: block;
  background-color: #169ee3;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  border: 0.5px solid #555;
}

.link2 {
  display: block;
  background-color: whitesmoke;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  border: 0.5px solid #555;
}

.link2.active2 {
  background-color: lightsteelblue;
  color: black;
}

.link2:hover:not(.active2) {
  background-color: #778899;
  color: white;
}

.active3 {
  color: black;
}

.nav2 {
  list-style-type: none;
  margin: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
  width: 220px;
  background-color: white;
}
.nav2title {
  text-align: center;
  display: block;
  background-color: #169ee3;
  color: rgb(48, 12, 12);
  padding: 10px;
  padding-left: 10px;
  text-decoration: none;
  border: 1px solid #555;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}
.col-md-9 {
  padding-left: 3em;
  padding-right: 1.5em;
  padding-top: 3em !important;
}
.col-md-3 {
  padding-left: 2.5em;
  padding-right: 3em;
  padding-top: 3em;
}
.contactus {
  text-align: center;
  font-size: 30px;
  margin-top: 2em;
  margin-bottom: 0.5em;
}
.col-md-12 {
  padding-right: 3.5em;
  padding-left: 3.5em !important;
}
.col-3 {
  padding-top: 1em;
  padding-bottom: 4em;
}

.strolltu {
  padding-top: 1em;
}
.linebreak {
  width: 400px;
}
.fluid-power-formulas {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0.5em;
}
.thankyou {
  padding-left: 3em;
  padding-top: 5em;
}
.thank-you-text {
  font-size: 40px;
  text-align: center;
  padding-top: 2.5em;
}
.bi-check-lg {
  margin-left: auto;
  margin-right: auto;
  color: green;
}
.thank-you-text-2 {
  text-align: center;
}

.simple-circuit {
  margin-left: auto;
  margin-right: auto;
}

.simple-hydraulic-simulation-title {
  padding-top: 1em;
  padding-left: 2.5em;
}
.maintenance-2-span {
  padding-left: 2em;
  position: relative;
  aspect-ratio: 1.75;
}

#maintenance-2 {
  position: absolute;
  object-fit: contain;
  object-position: left center;
  margin-right: 1em;
  margin-left: 1.5em;
}

.preventive-maintenance-text {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 1em;
  padding-bottom: 10em;
}

.oil-analysis-span {
  padding-left: 2em;
  position: relative;
  aspect-ratio: 1.75;
}

#oil-analysis2 {
  position: absolute;
  object-fit: contain;
  object-position: left center;
  margin-right: 1em;
  margin-left: 1.5em;
}

.oil-analysis-text {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 1em;
}

/* Product Folder */

.directional-control-valves-div {
  position: relative;
  height: 85px;
}

#directional-control-valves,
#flow-control-valves,
#piston-pump,
#radial-piston-pump,
#vane-pump,
#double-vane-pump,
#variable-displacement-vane-pump,
#gear-pump,
#dump-pump,
#geroler-motors,
#vane-motors,
#linear-guides,
#ball-screws,
#maintenance1,
#oilanalysis2 {
  position: absolute;
  object-fit: contain;
  object-position: center;
}

/* Flow Control Valves */

.flow-control-valves-div {
  position: relative;
  height: 128px;
}

/* Piston Pumps */

.piston-pump-div {
  position: relative;
  height: 287px;
}

.radial-piston-pump-div {
  position: relative;
  height: 130px;
}

/* Vane Pumps */

.vane-pump-div {
  position: relative;
  height: 117px;
}

.double-vane-pump-div {
  position: relative;
  height: 129px;
}

.variable-displacement-vane-pump-div {
  position: relative;
  height: 120px;
}

/* Gear Pumps */

.gear-pump-div {
  position: relative;
  height: 105px;
}
.gear-pump-div {
  position: relative;
  height: 105px;
}

.dump-pump-div {
  position: relative;
  height: 108px;
}

/* Motors */
.geroler-motors-div {
  position: relative;
  height: 137px;
}
.vane-motors-div {
  position: relative;
  height: 132px;
}

.piston-pump,
.fixed-piston-pump,
.gear-pump-title-text,
.dump-pump-title-text,
.product-title-text {
  text-align: center;
  margin-top: 3em;
  font-size: 20px !important;
}

.rexrothpump,
.fixed-piston-pump-pic,
.triple-gear-pump-pic,
.dump-pump-pic,
.product-pic {
  display: block;
  margin-left: auto;
  margin-right: auto !important;
}

.btn-info {
  display: block;
  margin-left: auto;
  width: 150px;
  margin-right: auto !important;
}

.a10-table-title,
.gear-pump-table-title,
.product-title-table-text {
  text-align: center;
  background-color: #016698 !important;
  color: white !important;
}
.inside-table-text {
  vertical-align: middle;
  text-align: center !important;
}

.pressure-piston-pump-rpm {
  text-align: center;
  background-color: lightblue !important;
}
.centering-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.part-number-search-text {
  margin-top: 50px;
  margin-bottom: 20px;
}

/* MANIFOLD `<a>` ELEMENTS */
.pdf-btns {
  display: inline-block !important;
}

/* Products - Linear Guides */
.linear-guides-div {
  position: relative;
  aspect-ratio: 1.5924855491;
  margin-left: auto;
  margin-right: auto;
}
/* 
Products - ball screws  */
.ball-screws-div {
  position: relative;
  aspect-ratio: 1.728135693;
  margin-left: auto;
  margin-right: auto;
}

/* PUMP FORM */

.main-div-pump-form {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 1em;
}

.pump-labels {
  width: 160px;
  display: inline-block;
  margin-bottom: 4px;
}
.input-fields {
  display: inline-block;
  margin-bottom: 1rem;
}

.hydraulic-pump-configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

.pump-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

/* PUMP FORM END */

/* FLOWMETER-FORM */

.flowmeter-form-labels {
  width: 150px;
  display: inline-block;
  margin-bottom: 4px;
}

.flowmeter-configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.flowmeter-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

/* FLOWMETER-FORM END */

/* MOTOR-FORM */

.motor-form-labels {
  width: 150px;
  display: inline-block;
  margin-bottom: 4px;
}

.hydraulicMotor1Configurator {
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.motor-labels-final {
  width: 100px;
  display: inline-block;
  margin-bottom: 4px;
}

.form-label-style {
  display: inline-block;
}

.form-display-none {
  display: none;
}

.form-display-block {
  display: block;
}

.form-textarea {
  width: 300px;
  height: 200px;
  display: block;
}

@media (max-width: 525px) {
  .form-textarea {
    width: 200px;
    height: 150px;
    display: block;
  }
}

/* MOTOR-FORM END */

/* TABLE-SORT-PART-NUMBER */

.table-sortable th {
  cursor: pointer;
}

.table-sortable .th-sort-asc::after {
  content: "\25b4";
}

.table-sortable .th-sort-desc::after {
  content: "\25be";
}

.table-sortable .th-sort-asc::after,
.table-sortable .th-sort-desc::after {
  margin-left: 5px;
}

.table-sortable .th-sort-asc,
.table-sortable .th-sort-desc {
  background: rgba(0, 0, 0, 0.1);
}

/* TABLE-SORT-PART-NUMBER END */

/* Interactive start */
.st0 {
  opacity: 0.8;
}
.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 10.2172, 10.2172;
}
.st3 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.st4 {
  stroke: #000000;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
.st5 {
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st6 {
  font-family: "MyriadPro-Regular";
}
.st7 {
  font-size: 91.3033px;
}
.st8 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #cccccc;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #cccccc;
}
.st11 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 15.3029, 15.3029;
}
.st12 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.2633, 12.2633;
}
.st13 {
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dasharray: 3;
}
.st14 {
  opacity: 0.5;
  fill: none;
  stroke: #ed1c24;
  stroke-width: 15;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st15 {
  opacity: 0.5;
  fill: none;
  stroke: #0000ff;
  stroke-width: 15;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #ffce3c;
}
.st17 {
  font-size: 34.5528px;
}
.st18 {
  font-size: 29.9357px;
}
.st19 {
  fill: #ff0000;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st20 {
  fill: #f2f2f2;
}
.st21 {
  font-size: 50px;
}
.st22 {
  fill: none;
  stroke: #000000;
  stroke-width: 15;
  stroke-miterlimit: 10;
}
.st23 {
  fill: #00ff00;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st24 {
  font-size: 30px;
}
/* Interactive end */

/* Contact Us beginning */

.svg-drawing-container {
  padding-top: 1em;
}

.contact-iframe {
  border: 0;
  padding-right: 2em;
  padding-bottom: 1em;
}

.contact-your-message-input {
  display: none;
}

/* Contact Us end */

/* Inventory beginning */
.invy-text {
  margin-top: 50px;
  margin-bottom: 20px;
  padding-right: 4em;
  padding-left: 4em;
}
/* Inventory End */

/* Formula beginning */
/* Force (lbs) = */
#pressure1 {
  width: 120px;
}
#area1 {
  width: 99px;
  margin: 0px 5px;
}

/* Gpm =  */
#rpm2 {
  width: 99px;
}
#displacement2 {
  width: 150px;
  margin-left: 5px;
}
.equals {
  margin-left: 5px;
  margin-right: 5px;
}

/* Hyd HP =  */
#gpm3 {
  width: 99px;
}
#psi3 {
  width: 120px;
  margin-left: 5px;
}

/* Torque (in lbs) =  */
#psi4 {
  width: 110px;
}
#area4 {
  width: 99px;
  margin-left: 5px;
}

/* Torque (in lbs) =  */
#hp5 {
  width: 99px;
}
#rpm5 {
  width: 99px;
  margin: 0px 5px;
}

/* Cylinder area (in²) = */
#dia6 {
  width: 110px;
}

/* EREA - Effected Rod End Area (in²) =  */
#dia12 {
  width: 110px;
}
#rod12 {
  width: 110px;
  margin-left: 5px;
}

/* Cyl Time (seconds) = */
#area7 {
  width: 99px;
}
#stroke7 {
  width: 99px;
  margin-left: 5px;
}
#gpm7 {
  width: 99px;
  margin: 0px 5px;
}

/* Cyl HP = */
#cylSpeed8 {
  width: 145px;
}
#cylForce8 {
  width: 125px;
  margin-left: 5px;
}

/* Cyl Adj. gpm on retract = */
#cylArea9 {
  width: 110px;
}
#gpm9 {
  width: 99px;
  margin-left: 5px;
}
#erea9 {
  width: 99px;
  margin: 0px 5px;
}

/* Cyl Speed (ft/min) = */
#stroke10 {
  width: 99px;
}
#time10 {
  width: 125px;
  margin: 0px 5px;
}

/* Cyl Speed (ft/min) = */
#gpm11 {
  width: 99px;
}
#area11 {
  width: 110px;
  margin: 0px 5px;
}
/* Formula end */

#fixedpic {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* contact form */
.contact-us-form {
  padding-left: 1em;
}
.get-in-touch {
  padding-left: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

.inventory-list {
  color: black;
  display: block;
  text-decoration: none;
}
.inventory-list:hover {
  color: #016698;
  text-decoration: underline;
  text-decoration-color: #016698;
}
.footer-margin {
  padding-bottom: 10em;
}
.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* social  */

.social-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  padding: 1em;
}
.see-more {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.see-more:hover {
  color: rgb(77, 77, 104);
  text-decoration: underline;
  text-decoration-color: none;
}
.see-less {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  display: none;
}
.see-less:hover {
  color: rgb(77, 77, 104);
  text-decoration: underline;
  text-decoration-color: none;
}

.video-blog-description {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: rgb(247, 242, 242);
  display: none;
}

.social-icons {
  display: flex;
  margin-right: 0.2em;
  margin-top: 0.1em;
  margin-bottom: 0.4em;
}
.fallback {
  display: flex;
  margin: 2em;
  margin-bottom: 3em;
}
.blog-input-spanish {
  margin-left: 4em;
}

/* Blog index */

.blog-div-image {
  position: relative;
  aspect-ratio: 1.9047;
  margin: auto;
  height: 315px;
}

@media (320px < width < 810px) {
  .blog-div-image {
    height: 230px;
    position: relative;
    aspect-ratio: 1.9047;
    margin: auto;
  }
}

#blog-image {
  position: absolute;
  object-fit: contain;
  object-position: center;
}

/* Blog-post-spanish */

.blog-post-spanish-space {
  margin-top: 0.2em;
}

/* Product Index */
/* Dropdown Button */
.dropbtn1,
.dropbtn2,
.dropbtn3,
.dropbtn4,
.dropbtn5,
.dropbtn6,
.dropbtn7,
.dropbtn8 {
  background-color: #0a567f;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-bottom: 2px;
}

/* Dropdown button on hover & focus */
.dropbtn1:hover,
.dropbtn1:focus,
.dropbtn2:hover,
.dropbtn2:focus,
.dropbtn3:hover,
.dropbtn3:focus,
.dropbtn4:hover,
.dropbtn4:focus,
.dropbtn5:hover,
.dropbtn5:focus,
.dropbtn6:hover,
.dropbtn6:focus,
.dropbtn7:hover,
.dropbtn7:focus,
.dropbtn8:hover {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown1,
.dropdown2,
.dropdown3,
.dropdown4,
.dropdown5,
.dropdown6,
.dropdown7,
.dropdown8 {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content1,
.dropdown-content2,
.dropdown-content3,
.dropdown-content4,
.dropdown-content5,
.dropdown-content6,
.dropdown-content7,
.dropdown-content8 {
  display: none;

  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content1 a,
.dropdown-content2 a,
.dropdown-content3 a,
.dropdown-content4 a,
.dropdown-content5 a,
.dropdown-content6 a,
.dropdown-content7 a,
.dropdown-content8 a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

/* Change color of dropdown links on hover */
.dropdown-content1 a:hover,
.dropdown-content2 a:hover,
.dropdown-content3 a:hover,
.dropdown-content4 a:hover,
.dropdown-content5 a:hover,
.dropdown-content6 a:hover,
.dropdown-content7 a:hover,
.dropdown-content8 a:hover {
  background-color: #ddd;
}
/* . {
  background-color: #ddd;
} */
.dropdown-title {
  color: #2980b9;
  padding: 10px;
  text-decoration: none;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */

.hydraulic-class-items,
.mechanical-class-items {
  display: block;
  text-decoration: none;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}
.do-not-show-hydraulics,
.do-not-show-mechanical {
  display: none;
  z-index: 1;
}

.hydraulic-button-wrap-div,
.mechanical-button-wrap-div {
  margin-bottom: 0.5em;
  border-bottom: double;
}

.hydraulic-drop-button:hover,
.mechanical-drop-button:hover {
  color: rgb(44, 68, 134);
  text-decoration: underline dotted rgb(44, 68, 134);
}

.show1,
.show2,
.show3,
.show4,
.show5,
.show6,
.show7,
.show8,
.showPumps {
  display: block;
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.product-centering {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.product-col {
  margin-bottom: 2em;
}

/* Finish product Index */

/* Filter Elements */

.filter-elements-first-col {
  margin-top: 4em;
}

.filter-element {
  color: black;
  text-decoration: none;
}

.filter-element:hover {
  color: blue;
  text-decoration: underline;
  text-decoration-color: red;
}
.contact-us {
  color: rgb(6, 6, 145);
  font-weight: bold;
  text-decoration: none;
}

.contact-us:hover {
  text-decoration: underline;
  text-decoration-color: blue;
}

/* Carousel */

.tu-world {
  position: relative;
  margin-top: 0.2em;
  aspect-ratio: 5.41;
  margin-left: auto;
  margin-right: auto;
}

/* .fill-carousel {
  background-color: #1e4474;
} */

#carousel-inner {
  animation-name: carousel-inner;
  animation-duration: 3s;
  position: absolute;
  object-fit: contain;
  object-position: 50% top;
}

@keyframes carousel-inner {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Carousel blog Spanish */

.carousel-spanish-div {
  position: relative;
  margin-top: 0.2em;
  aspect-ratio: 1.7777777778;
  margin-left: auto;
  margin-right: auto;
}

/* .fill-carousel {
  background-color: #1e4474;
} */

#carousel-inner-spanish-blog {
  animation-name: carousel-inner;
  animation-duration: 1s;
  position: absolute;
  object-fit: contain;
  object-position: 50% top;
}

@keyframes carousel-inner-spanish-blog {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.c-image {
  display: block;
  height: auto;
}

/* CHAT UI */
.chat-button {
  background-color: #555;
  color: black;
  padding: 10px 20px;
  border: 3px solid #f1f1f1;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  left: 28px;
  width: 280px;
}
.inside-chat-button {
  background-color: #0a6d49;
  color: white;
  padding: 3px 15px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 40%;
  margin-bottom: 5px;
}
.chat-container {
  display: none;
  background-color: #555;
  position: fixed;
  bottom: 23px;
  left: 28px;
  width: 280px;
  border: 3px solid #f1f1f1;
}

.btn-container {
  max-width: 280px;
  padding: 5px 10px;
  background-color: #555;
}
.btn-send {
  background-color: #04aa6d;
  color: white;
  padding: 5px 20px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}
/* Using btn-close2 because Bootstrap already has a btn-close class */
.btn-close2 {
  background-color: red;
  color: white;
  padding: 5px 20px;
  border: 2px solid #f1f1f1;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}
.input-container {
  position: relative;
  box-shadow: 0px 2px 5px 0px rgba (0, 0, 0, 0.3);
  background-color: white;
  color: black;
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
  transition: 0.4s;
}
.chat-display {
  position: relative;
  overflow-y: auto;
  height: 250px;
  background-color: white;
  color: black;
  padding: 2px;
  border: none;
  cursor: pointer;
  margin-bottom: 1px;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
}
.chat-display-header {
  color: white;
  padding-left: 10px;
}
.ai-col:before {
  content: "AI-Luis.";
}
.message_container {
  position: relative;
  margin-top: 5px;
}
.message_text {
  word-wrap: break-word;
}
/* Google tags */

.main-cookies {
  display: none;
  background-color: whitesmoke;
  color: black;
  margin: auto;
  padding: 10px 20px;
  border: 5px solid black;
  cursor: pointer;
  position: fixed;
  top: 30%;
  left: 5%;
}

/* Quick Contact English  */
.quick-contact-english-text {
  text-align: center;
  background-color: #f4f4f4;
  margin-top: 0.2em;
}
